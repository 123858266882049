import React from "react"
import styled from "styled-components"

const AboutWrapper = styled.div`
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
`

/* const About = () => (
 *   <AboutWrapper>
 *     Notes about my random experiences with Linux, Emacs, Development and Tinkering
 *   </AboutWrapper>
 * )
 *  */

const About = () => <></>

export default About
