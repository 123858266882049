import React from "react"
import { Link } from "gatsby"
import { Post } from "../models"
import styled from "styled-components"

const Date = styled.div`
  display: inline-block;
  font-family: monospace;
  margin-right: 0.5rem;
`

const PostPreview = (props: Post) => (
  <>
    <dt>
      <Date>{props.isoDate}</Date>
    </dt>
    <dd>
      <Link to={props.slug}>{props.title}</Link>
    </dd>
  </>
)

export default PostPreview
