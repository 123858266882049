import React from "react"
import styled from "styled-components"

const SvgButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  &:focus {
    border: 0;
    outline: none;
  }
  span {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }
`
const Sun = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    aria-hidden="true"
  >
    <circle
      className="shine"
      cx="12"
      cy="12"
      r="11"
      fill="transparent"
      strokeDasharray="2,3"
    ></circle>
    <circle className="sun" cx="12" cy="12" r="8" fill="transparent" stroke="black"></circle>
  </svg>
)

const Moon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    aria-hidden="true"
  >
    <path className="moon" d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
  </svg>
)

const ModeButton = (props) => {
  return (
    <ModeButtonWrapper onClick={props.toggleThemeMode}>
      {props.isDarkMode ? <Sun /> : <Moon />}
    </ModeButtonWrapper>
  )
}

export default ModeButton

const ModeButtonWrapper = styled(SvgButton)`
  .moon {
    fill: ${(props) => props.theme.colors.text};
  }

  .sun,
  .shine {
    stroke: ${(props) => props.theme.colors.text};
  }

  &:hover {
    .moon {
      fill: ${(props) => props.theme.colors.primary};
    }

    .sun,
    .shine {
      stroke: ${(props) => props.theme.colors.primary};
    }
  }
`
