export default {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "Kepi.cz", // Navigation and Site Title
  siteTitleAlt: "Kepi.cz", // Alternative Site title for SEO
  siteDescription: "Notes about my random experiences with Linux, Emacs, Development and Tinkering",
  author: "Kepi", // Author for schemaORGJSONLD
  siteUrl: "https://kepi.cz", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteLogo: "/assets/logo.png", // Image for schemaORGJSONLD
  siteBanner: "/assets/banner.jpg", // Your image for og:image tag. You can find it in the /static folder
  favicon: "src/favicon.svg", // Your image for favicons. You can find it in the /src folder
  iconMaskable: "src/assets/maskable.svg",

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@kepicz", // Twitter Username - Optional

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: "#3498DB",
  backgroundColor: "#2b2e3c",
}
