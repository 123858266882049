import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.bg};
    color: ${(props) => props.theme.colors.text};
  }

  a {
    color: ${(props) => props.theme.colors.primary};

    text-underline-offset: 1px;

    &:hover {
      color: ${(props) => props.theme.colors.primaryAlt};
    }
  }

  h1 {
    color: ${(props) => props.theme.colors.header};
    a {
      color: ${(props) => props.theme.colors.header};
    }
  }

  h2 {
    color: ${(props) => props.theme.colors.primaryAlt};
    a {
      color: ${(props) => props.theme.colors.primaryAlt};
    }
  }

  h3 {
    color: ${(props) => props.theme.colors.secondary};
    a {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
  h4 {
    color: ${(props) => props.theme.colors.secondaryAlt};
    a {
      color: ${(props) => props.theme.colors.secondaryAlt};
    }
  }

  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    dl {
      display: grid;
      column-gap: 2em;
      grid-template-columns: max-content auto;

      div {
       display: contents;
      }
    }

    dt {
      grid-column-start: 1;
      &::after {
        content: ":: ";
      }

      &::before {
        content: "•";
        padding-right: 0.5em;
      }
    }

    dd {
      grid-column-start: 2;
      margin-bottom: 0px;
    }
  }
`
