import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { License } from "../components"

const FooterWrapper = styled.footer`
  margin: 3rem 0;
  padding: 1rem 0;
  font-size: 0.75rem;

  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.bgBorder} 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 20px 1px;
  background-repeat: repeat-x;
  a {
    color: ${(props) => props.theme.colors.bgBorder};
  }
`

const Build = styled.div`
  margin-top: 1rem;
`

interface DataProps {
  site: {
    siteMetadata: {
      title: string
    }
    buildTime: string
    buildYear: string
  }
}

const Footer = () => {
  const query = graphql`
    {
      site {
        buildTime(fromNow: true)
        buildYear: buildTime(formatString: "YYYY")
      }
    }
  `
  const render = (data: DataProps) => {
    return (
      <FooterWrapper>
        <License year={data.site.buildYear} />

        <Build>
          Generated from <a href="https://orgmode.org/">Org-mode</a> using{" "}
          <a href="https://www.gatsbyjs.com/">GatsbyJS</a> with{" "}
          <a href="https://orga.js.org/">Orga.js</a> {data.site.buildTime}.
        </Build>
      </FooterWrapper>
    )
  }

  return <StaticQuery query={query} render={render} />
}

export { Footer }
