import React from "react"
import { Content } from "../components"

const PostList = ({ children }) => (
  <Content>
    <h1>Blog</h1>

    <dl>{children}</dl>
  </Content>
)
export default PostList
