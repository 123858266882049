import React from "react"
import { Box } from "atomic-layout"
import { Link } from "gatsby"
import styled from "styled-components"

const MenuItem = styled(Link)`
  padding: 0px 0.6rem;

  border-right: 1px solid ${(props) => props.theme.colors.text};

  :last-of-type {
    border-right: 0px;
  }
`

const Menu = () => {
  return (
    <Box flex justifyContent="start" alignItems="center">
      <MenuItem to="/blog">Blog</MenuItem>
      <MenuItem to="/projects">Projects</MenuItem>
    </Box>
  )
}

export default Menu
