import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import { Footer } from "./Footer"
import { Header } from "../components"

import { darkTheme, lightTheme } from "../theme"
import { GlobalStyles } from "../style/global"
import About from "./About"
import { wantDarkMode } from "../utils/dark-mode"

const Site = styled.div`
  max-width: 1000px;
  margin: auto;
  padding: 0 1rem;
  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    padding: 0;
  }
`

const Layout = ({ children, about }: { children: any; about?: Boolean }) => {
  const toggleThemeMode = () => {
    setIsDarkMode(!isDarkMode)
    if (typeof window !== "undefined") {
      localStorage.setItem("isDarkMode", `${!isDarkMode}`)
    }
  }

  const [isDarkMode, setIsDarkMode] = useState(wantDarkMode())
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Site>
        <GlobalStyles />
        <Header isDarkMode={isDarkMode} toggleThemeMode={toggleThemeMode} />
        {about ? <About /> : null}

        {children}
        <Footer />
      </Site>
    </ThemeProvider>
  )
}

export default Layout
