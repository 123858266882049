import React from "react"
import { Box } from "atomic-layout"
import { Menu, ModeButton, SvgButton } from "../components"
import { Link } from "gatsby"
import styled from "styled-components"

const HeaderWrapper = styled.div`
  line-height: 1.5rem;
  padding-left: 0rem;
  margin: 1rem 0 2rem 0;

  a {
    color: #268bd2;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const RssLink = styled.a`
  display: block;
  line-height: 0;
`
const Rss = styled.svg`
  stroke: ${(props) => props.theme.colors.text};

  &:hover {
    stroke: ${(props) => props.theme.colors.primary};
  }
`

const Header = (props) => {
  return (
    <Box
      gapCol={2}
      marginBottom="2rem"
      as={HeaderWrapper}
      alignItems="stretch"
      height="1.5rem"
      flex
      justifyContent="space-between"
    >
      <Link to="/">Kepi.cz</Link>
      <Menu />
      <Buttons>
        <ModeButton isDarkMode={props.isDarkMode} toggleThemeMode={props.toggleThemeMode} />
        <RssLink href="/rss.xml">
          <Rss
            width={22}
            height={22}
            fill="transparent"
            stroke="#000"
            strokeWidth={2}
            viewBox="0 4 20 20"
          >
            <circle cx={4} cy={20} r={1} fill="transparent" />
            <circle cx={4} cy={20} r={2} fill="transparent" />
            <circle cx={4} cy={20} r={8} fill="transparent" />
            <circle cx={4} cy={20} r={14} fill="transparent" />
          </Rss>
        </RssLink>
      </Buttons>
    </Box>
  )
}
export default Header
