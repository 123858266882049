const wantDarkMode = () => {
  if (typeof window !== "undefined") {
    const stored = window.localStorage.getItem("isDarkMode")
    if (stored === "true") {
      return true
    } else if (stored === "false") {
      return false
    }

    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return true
    } else {
      return false
    }
  }

  // default
  return true
}

export { wantDarkMode }
