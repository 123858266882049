import { transitions, sizes, solarized } from "./common"

const darkTheme = {
  dark: true,
  colors: {
    primary: solarized.magenta, // Color for buttons or links
    primaryAlt: solarized.violet,
    secondary: solarized.orange,
    secondaryAlt: solarized.red,
    text: solarized.base0,
    header: solarized.red,
    bg: solarized.base02,
    bgAlt: solarized.base03,
    bgBorder: solarized.base1,
  },
  transitions,
  sizes,
}

export default darkTheme
