import styled from "styled-components"
// FIXME: dark/light maybe something like this
// https://prawira.medium.com/react-conditional-import-conditional-css-import-110cc58e0da6
import "highlight.js/styles/solarized-dark.css"

const Content = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: list-item;
    margin-left: 1em;
  }
  h2 {
    list-style-type: "◉ ";
  }

  h3 {
    list-style-type: "○ ";
  }
  h4 {
    list-style-type: "✸ ";
  }
  h5 {
    list-style-type: "✿ ";
  }
  h6 {
    list-style-type: "◉ ";
  }

  .section blockquote {
    background-color: ${(props) => props.theme.colors.bgAlt};
    border-left: 6px solid ${(props) => props.theme.colors.primaryAlt};
    box-shadow: 5px 5px rgba(0, 0, 0, 0.4);
    padding: 1rem 1rem 1rem 2rem;
    font-style: italic;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  code {
    background-color: ${(props) => props.theme.colors.bgAlt};
    padding: 0 4px;
    user-select: all;
  }

  pre code {
    box-shadow: 5px 5px rgba(0, 0, 0, 0.4);
    left: -5%;
    position: relative;
    padding: 1rem;
    width: 110%;
    font-size: 13px;
    user-select: auto;
  }

  & > div > figure {
    position: relative;
    width: 110%;
    left: -5%;
    img {
      box-shadow: none;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    /* FIXME: az budou fungovat atributy v Orga */
    /* box-shadow: 5px 5px rgba(0, 0, 0, 0.4); */
  }

  figure {
    width: fit-content;
    margin: auto;

    figcaption {
      font-size: 0.8em;
    }
  }

  @media (min-width: ${(props) => props.theme.sizes.min.m}) {
    div > .section > * {
      padding-left: 2rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-left: 0 !important;
    }

    .section blockquote {
      margin-left: 2rem;
    }
  }
`
export default Content
