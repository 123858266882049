import { transitions, sizes, solarized } from "./common"
const lightTheme = {
  dark: false,
  colors: {
    primary: solarized.magenta, // Color for buttons or links
    primaryAlt: solarized.violet,
    secondary: solarized.orange,
    secondaryAlt: solarized.red,
    text: solarized.base00,
    header: solarized.violet,
    bg: solarized.base3,
    bgAlt: solarized.base2,
    bgBorder: solarized.base1,
  },
  transitions,
  sizes,
}

export default lightTheme
